import React from 'react';

import SiteWidth from '../../layout/SiteWidth';
import Form from '../Form';
import HtmlBlock from '../../utilities/HtmlBlock';
import FrameMargin from '../../layout/FrameMargin';
import Raindrops from '../../graphics/Raindrops';
import Button from '../../controls/Button';

import './index.scss';

const CallToAction = props => {
	const { margin, id, title, introText, showForm, formGroup, ctaLink } = props;
	const componentMargin = margin && margin !== 'none' ? ` margin-${margin}` : '';

	return (
		<div id={id} className={`cta-block${showForm ? ' with-form' : ' without-form'} ${componentMargin}`}>
			< FrameMargin side="right" >
				<div className="bg-color">
					<Raindrops />
					<Raindrops className="blue-raindrops" color="#1DCAD3" />
				</div>
			</FrameMargin >
			<SiteWidth>
				<h1 className="heading">{title}</h1>
				{showForm
					?
					<div className="form-section row">
						<HtmlBlock className="form-intro" content={introText} />
						<Form
							key={window.location.pathname}
							id={`${id}-form`}
							useFrameBackground={false}
							formCode={formGroup.formCode}
						/>
					</div>
					:
					<Button className="cta-button" type={ctaLink.button.buttonType} arrow={ctaLink.button.arrow} url={ctaLink.button.pageUrl}>{ctaLink.button.label}</Button>
				}
			</SiteWidth>
		</div >
	)
}

export default CallToAction;

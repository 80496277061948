import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

import MeasureScroll from './components/utilities/MeasureScroll';
import MeasureWindow from './components/utilities/MeasureWindow';
import WatchSearchQuery from './components/utilities/WatchSearchQuery';
import NotFound from './components/templates/NotFound';
// import Sidebar from './components/layout/Sidebar';
// import Header from './components/layout/Header';
import LoadTemplate from './components/templates/LoadTemplate';
import api from './api';

import './scss/app.scss';

const mapStateToProps = (state) => ({
	pageList: state.api.lists.pages
});


const mapDispatchToProps = dispatch => ({
	loadGlobals: data => dispatch({ type: 'LOAD_GLOBAL_DATA', payload: data })
});
class App extends Component {

	constructor(props) {
		super(props);


		this.buildRoutes = (pages) => {

			if (this.props.pageList && this.props.pageList.length > 0) {
				return [
					<Route
						key="posts"
						render={(props) =>
							<LoadTemplate
								{...props}
								template="post"
								type="post" />
						}
						exact
						path="/post/:slug" />,

					pages.map((route, i) => {

						// If home, set path to empty string, = '/'
						if (route.slug === 'home') {
							route.path = '';
						}

						// If template is blank, set to default
						if (route.template === '') {
							route.template = 'default'
						}

						// Default WP REST API expects /pages/ and /posts/ formatting
						// Custom post types are all singular (sigh)
						route.type = route.type === 'page'
							? 'pages'
							: route.type === 'post'
								? 'posts'
								: route.type;

						return (
							<Route
								render={(props) =>
									<LoadTemplate
										{...props}
										template={route.template}
										slug={route.slug}
										type={route.type} />
								}
								exact
								key={i}
								path={`/${decodeURIComponent(route.path)}`} />
						)
					}),

					<Route exact key="wp-draft" page="/wp-draft" render={props =>
						<LoadTemplate {...props} slug={'wp-draft'} type={'pages'} />} />,

					<Route key="not-found" component={NotFound} />
				]
			}
		}
	}

	componentDidMount() {
		this.props.loadGlobals(api.Content.globals());
	}

	render() {
		return (
			<div className={`app ` + window.location.pathname}>
				
				<Switch>
					{this.buildRoutes(this.props.pageList)}
				</Switch>
				<MeasureWindow />
				<MeasureScroll />
				<WatchSearchQuery />
			</div>
		);
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

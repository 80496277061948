import React from 'react';

import './index.scss';

const SiteWidth = React.forwardRef((props, ref) => {
  const {
    className,
    children,
  } = props;

  const baseName = 'site-width';
  const classes = [
    baseName,
    className,
  ].filter(Boolean).join(' ');

  return (
    <div className={classes} ref={ref}>
      {children}
    </div>
  );
});

export default SiteWidth;

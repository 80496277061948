import React from 'react';
import Video from '../../elements/Video';
import FrameMargin from '../FrameMargin';
import SiteWidth from '../SiteWidth';
import HtmlBlock from '../../utilities/HtmlBlock';
import Raindrops from '../../graphics/Raindrops';
import ButtonGroup from '../../elements/ButtonGroup';

import './index.scss';

const baseName = 'large-hero';

const LargeHero = ({ backgroundVideo, backgroundImage, content, ctas }) => {
	return (
		<section className={baseName}>
			<FrameMargin side={'left'}>
				{backgroundVideo &&
					<Video
						src={backgroundVideo.url}
						poster={backgroundImage && backgroundImage.url}
						htmlVideoAttributes={{ autoPlay: true, loop: true, muted: true, playsInline: true }} />
				}
			</FrameMargin>
			<SiteWidth className={`${baseName}__content-wrap`}>
				<div className={`${baseName}__content-inner-wrap`}>
					<div className={`${baseName}__content-wrap-row`}>
						<HtmlBlock content={content} />
						{ctas && <ButtonGroup buttons={ctas.map(cta => cta.button)} />}
					</div>
				</div>
			</SiteWidth>
			<Raindrops />
		</section>
	);
}

export default LargeHero;

import React from 'react';

const LogoName = props => {

	const colors = {
		primary: '#003057'
	}

	return (
		<svg className="logo-name graphic" version="1.1" id="Layer_1" viewBox="0 0 108.339996 50" width="108.339996" height="50" xmlns="http://www.w3.org/2000/svg">
			<path fill={colors.primary} d="M108.339478,49.092182h-7.585442l-8.14669-21.385273l-3.861977,6.970535v14.414738h-7.163651V0.908407h7.163651 v21.993429h0.140488l10.46389-21.993429h7.163605l-9.761398,19.286573L108.339478,49.092182z M68.369858,0.877193
				l10.826401,48.245453h-6.945641l-0.582077-2.957901c-0.003265,0-0.00605,0.000961-0.008835,0.000961l-0.775787-3.939819
				c-1.580971,1.067848-3.453682,1.665672-5.414627,1.665672c-1.959549,0-3.830383-0.596912-5.410389-1.66428l-1.358353,6.895367
				H51.75491l1.301388-5.800587h-0.002838l9.522285-42.432819h0.003265l0.002785-0.012046H68.369858z M69.389732,33.526398
				l-3.825676-19.641113h-0.189049l-3.825722,19.641113c-0.477951,2.452061,1.410126,4.729904,3.920006,4.729904
				C67.979607,38.256302,69.866821,35.979366,69.389732,33.526398z M49.843277,9.873882
				c0.094604,0.679739,0.157246,1.38084,0.157246,2.125361v0.072332v25.857277v0.07233c0,0.744095-0.062641,1.446587-0.157246,2.124935
				c-0.148571,1.090118-0.40551,2.083763-0.786766,2.964489c-0.020557,0.049149-0.034744,0.1021-0.056217,0.151249
				c-0.666996,1.516991-1.554787,2.781063-2.667503,3.793175c-1.067043,0.965321-2.278057,1.699936-3.633945,2.206207
				C41.342899,49.747562,39.976032,50,38.596798,50c-1.376984,0-2.744759-0.252438-4.101616-0.758762
				c-1.35498-0.506271-2.589334-1.240887-3.701138-2.206207c-1.066509-1.012112-1.933315-2.276184-2.60026-3.793175
				c-0.021523-0.049149-0.036139-0.1021-0.056269-0.151249c-0.381256-0.880726-0.638193-1.874371-0.786766-2.964489
				c-0.095085-0.678349-0.157246-1.38084-0.157246-2.124935v-0.07233V12.071576v-0.072332
				c0-0.744521,0.06216-1.445622,0.157246-2.125361c0.148573-1.09012,0.40551-2.083333,0.786766-2.964006
				c0.02013-0.049632,0.034746-0.102582,0.056269-0.151303c0.666945-1.516991,1.55479-2.781061,2.667452-3.793174
				c1.067043-0.965319,2.277145-1.699936,3.633945-2.206688C35.851128,0.252921,37.217995,0,38.596798,0
				c1.377415,0,2.745193,0.252921,4.102047,0.75871c1.35498,0.506752,2.589336,1.241369,3.701138,2.206688
				c1.066509,1.012113,1.933315,2.276183,2.600311,3.793174c0.021473,0.048721,0.03566,0.101672,0.056217,0.151303
				C49.437767,7.790549,49.694706,8.783762,49.843277,9.873882z M43.198956,12.071576c0-1.701328-0.45578-2.95453-1.366863-3.758641
				c-0.911568-0.804594-1.989532-1.206408-3.235294-1.206408c-1.243454,0-2.322815,0.401814-3.234863,1.206408
				c-0.910652,0.804111-1.366863,2.057313-1.366863,3.758641v25.857277c0,1.701382,0.45578,2.955013,1.366863,3.758694
				c0.91114,0.804596,1.989586,1.206837,3.234863,1.206837c1.243889,0,2.322819-0.402241,3.235294-1.206837
				c0.910172-0.80368,1.366863-2.057312,1.366863-3.758694V12.071576z M13.914986,21.866058l0.010226-0.020397
				c-1.801929-0.964571-6.947515-4.624645-6.947515-8.646059c0-4.031854,1.748497-6.076264,5.197625-6.076264
				c1.47068,0,4.913758,0,4.913758,8.238729h6.977657C24.066738,1.496646,15.750323,0,12.175323,0
				c-7.282404,0-12.1748,5.304705-12.1748,13.199602c0,8.977093,9.902793,14.530275,10.646512,14.934767l-0.010708,0.020399
				c1.802839,0.965052,6.947942,4.624163,6.947942,8.645632c0,4.032333-1.748496,6.076691-5.198053,6.076691
				c-1.469769,0-4.91333,0-4.91333-8.239639H0.494318C0.494318,48.502872,8.811697,50,12.386215,50
				c7.282404,0,12.175711-5.304222,12.175711-13.1996C24.561926,27.827534,14.668877,22.275797,13.914986,21.866058z"/>
		</svg>
	)
}

export default LogoName;
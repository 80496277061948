import React, { Component } from 'react';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';

class NotFound extends Component {

	render() {

		return (
			<div>
				<Sidebar />
				<Header />
				<article className="not-found">
					<h1>Not Found</h1>
				</article>
			</div>
		);
	};
};

export default NotFound;
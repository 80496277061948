import React from 'react';
import { connect } from 'react-redux';

import './index.scss';

const mapStateToProps = state => ({
	contentWidth: state.browser.contentWidth,
	windowWidth: state.browser.windowWidth,
	windowHeight: state.browser.windowHeight
});

const baseName = 'grid-bleed';

const GridBleed = props => {

	const { contentWidth, windowWidth, cols, children, reference } = props;
	let width = 0;

	if (reference === 'window') {
		let colWidth = contentWidth;
		let gutter = 0;

		if (cols) {
			colWidth = 12 / cols * contentWidth;
			gutter = (windowWidth - colWidth) / 2;
			width = contentWidth + gutter;
		} else {
			gutter = (windowWidth - contentWidth) / 2;
			width = colWidth + gutter;
		}
	} else {
		let colWidth = contentWidth;

		if (cols) {
			colWidth = cols / 12 * contentWidth;
		}

		const gutter = (windowWidth - contentWidth) / 2;
		width = colWidth + gutter;
	}

	return (
		<div className={baseName}>
			<div className="contents" style={{ width: `${width}px` }}>
				{children}
			</div>
		</div>
	);
}

export default connect(mapStateToProps)(GridBleed);

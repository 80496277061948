import React from 'react';
import { Link } from 'react-router-dom';


import '../Landing/index.scss';
import logoSrc from '../../graphics/Landing/soak-logo.svg';
import lushLawn from '../../graphics/Landing/lush-lawn.jpg';
import sprinklerSpray from '../../graphics/Landing/sprinkler-spray.jpg';

const LandingTwo = (props) => {

	const { data } = props;

	if (data) {
		// const { acf } = data;

		return (
			<article className="landing">
				
				<header className="landing-header">
					<div className="o-centerContainer">
						<Link to="/">
							<img src={logoSrc} alt="Soak Irrigation"/>
						</Link>
					</div>
				</header>
				<section className="landing-hero landing-hero--two">
					<div className="o-centerContainer">
						<h1>Relax, enjoy your summer, and let Soak do the work.</h1>
						<a href="/get-a-quote#Form-0" className="c-button c-button--green">Request a Quote</a>
					</div>
				</section>
				<section className="landing-text landing-theme--orange">
					<div className="landing-text-inner o-centerContainer">
						<h2>Keeping up with your luscious lawn has never been easier. </h2>
						<p><strong>With the start of spring finally here and summer approaching before we know it, there’s no better time than now to lock down your maintenance services for your landscape’s irrigation system.</strong> </p>
						<p>Lawn maintenance is easy and inexpensive for full-time beauty and growth: we’ll come by for spring set-up, a summer check-in, and winter shutdown, and the other 362 days of the year, you won’t even have to think about your yard!</p>
						<p>During our irrigation system inspections, we’ll assess your lines for any needed repairs like sprinkler head replacements, or regular maintenance like cleaning. With Soak taking care of your irrigation maintenance needs, you can rest assured that you’ll have the greenest, softest grass in the neighborhood. And you won’t even have to lift a finger. </p>
					</div>
				</section>


				<section className="landing-text landing-theme--green-drops">
					<div className="landing-text-inner o-centerContainer">
						<h2>Effortless greenery. <br/>Effortless memories.</h2>
						<p><strong>In just one phone call, you can effortlessly give your children a soft, beautiful yard to play in on warm summer days and sprinklers to run through and cool down with.</strong></p>
						<p>And because we’ll be taking care of your maintenance, you have one less thing to worry about, and more time and energy to do the things you love, like spend quality time with your family.</p>
						<p>Give yourself more freedom to enjoy doing what you love and have the confidence that comes from having a stunning emerald green landscape that your neighbors will notice, and your kids will love.</p>
						<a href="/get-a-quote#Form-0" className="c-button c-button--blue">Request a Quote</a>
					</div>
				</section>


				<section className="c-image-wrapper">
					<div className="c-image-showcase">
						<div className="c-image-showcase__item">
							<img src={lushLawn} alt="Lush Lawn"/>
						</div>
						<div className="c-image-showcase__item">
							<img src={sprinklerSpray} alt="Sprinkler Spray"/>
						</div>
					</div>
				</section>

				<section className="landing-text landing-theme--multi-color">
					<div className="landing-text-inner o-centerContainer">
						<h2>What are you waiting for?</h2>
						<h3>Call today and secure your maintenance now before we’re booked for the season!</h3>
						<p>Make sure that you’ve got yourself set-up to have the best summer yet. </p>
						<a href="tel:+16167346626" className="c-button">Call 616-734-6626</a>
						<a href="/get-a-quote#Form-0" className="c-button">Request a Quote</a>
					</div>
				</section>

				<footer className="landing-footer">
					<div className="o-centerContainer landing-footer__inner">
						<div className="landing-footer-info">
							<a href="tel:+16167346626">616-734-6626</a>
							<address>1505 Steele Ave SW <br/>Grand Rapids, MI 49507</address>
							<a href="mailto:info@soakirrigation.com">info@soakirrigation.com</a>


						</div>
						<div className="landing-footer-social">
							
						</div>
						<div className="landing-footer-logo">
							<Link to="/">
								<img src={logoSrc} alt="Soak Irrigation"/>
							</Link>
						</div>
					</div>
				</footer>

				
			</article>
		);
	}

	return null;
};

export default LandingTwo;

import React from 'react';
import { connect } from 'react-redux';

import SocialMedia from '../SocialMedia';

import './index.scss';

const mapStateToProps = (state) => ({
	socialMediaLinks: state.api.socialMediaLinks
})

const SocialMediaLinks = props => {

	if (props.socialMediaLinks) {
		return (
			<ul className="social-media-links">
				{props.socialMediaLinks.map((link, i) => {
					return (
						<li key={i}>
							<SocialMedia url={link.url} icon={link.icon} />
						</li>
					)
				})}
			</ul>
		);
	}

	return null;
}

export default connect(mapStateToProps)(SocialMediaLinks);

import React from 'react';

const LinkedIn = props => {

	const colors = {
		primary: '#003057',
		secondary: '#ffffff'
	}

	return (
		<svg className="graphic linkedin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
			<path className="fill" fill={colors.secondary} d="M12.7533,11.077v-0.02619L12.7356,11.077H12.7533z"/>
			<path className="fill" fill={colors.primary} d="M0,0v24h24V0H0z"/>
			<rect x="5.88086" y="9.90308" className="fill" fill={colors.secondary} width="2.691471" height="8.09692"/>
			<path className="fill" fill={colors.secondary} d="M7.22681,8.79834h-0.018C6.435351,8.844836,5.770645,8.255517,5.724148,7.482058
				S6.266971,6.043893,7.04043,5.997396C7.10843,5.993309,7.176637,5.994179,7.24451,6
				c0.773126-0.056108,1.445353,0.525151,1.501461,1.298277s-0.525151,1.445353-1.298277,1.50146
				C7.374144,8.805075,7.300287,8.804607,7.22681,8.79834z"/>
			<path className="fill" fill={colors.secondary} d="M18.279051,18H15.58746v-4.33185c0-1.08825-0.38934-1.83087-1.36358-1.83087
				c-0.622104,0.003577-1.174864,0.397668-1.38105,0.98462c-0.069187,0.211555-0.09944,0.433901-0.08929,0.65625V18H10.061
				c0,0,0.03625-7.33679,0-8.09692h2.6923v1.1477c0.496496-0.863002,1.43147-1.378563,2.42627-1.33789
				c1.771481-0.00006,3.09948,1.15723,3.09948,3.64429L18.279051,18z"/>
		</svg>
	)
}

export default LinkedIn;

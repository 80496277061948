import React from 'react';
import FrameMargin from '../FrameMargin';
import SiteWidth from '../SiteWidth';
import ButtonGroup from '../../elements/ButtonGroup';
import GridBleed from '../../layout/GridBleed';

import './index.scss';

const baseName = 'medium-hero';

const MediumHero = ({ backgroundImage, heading, subheading, primaryImage, ctas }) => {

	const classes = [baseName];
	if (primaryImage) classes.push(`${baseName}--has-primary-image`);

	return (
		<section className={classes.join(' ')}>
			<div className={`${baseName}__hero-bg-title-wrap`}>
				<FrameMargin side={'left'}>
					<div className={`${baseName}__hero-bg`} style={{ backgroundImage: backgroundImage && `url(${backgroundImage.url})` }} />
				</FrameMargin>
				<SiteWidth className={`${baseName}__heading-wrap`}>
					<div className={`${baseName}__heading-row`}>
						<h1>{heading}</h1>
					</div>
				</SiteWidth>
			</div>
			{primaryImage &&
				<GridBleed cols={5}>
					<img src={primaryImage.url} alt={primaryImage.alt} />
				</GridBleed>
			}
			<SiteWidth className={`${baseName}__subheading-wrap`}>
				<div className={`${baseName}__subheading-row`}>
					<div className={`${baseName}__subheading-column`}>
						{subheading && <p className="large-body">{subheading}</p>}
						{ctas && <ButtonGroup buttons={ctas.map(cta => cta.button)} />}
					</div>
				</div>
			</SiteWidth>
		</section>
	)
}

export default MediumHero;

import React from 'react';

const Logo = props => {

	const colors = {
		primary: '#003057'
	}

	return (
		<svg className="logo graphic" width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path fill={colors.primary} d="M11.99977,24C5.383135,24,0,18.616899,0,12S5.383135,0,11.99977,0C18.616711,0,24,5.383101,24,12
        S18.616711,24,11.99977,24 M11.99977,1.277331C6.087336,1.277331,1.277341,6.087603,1.277341,12
        S6.087336,22.722668,11.99977,22.722668S22.722507,17.912397,22.722507,12S17.912205,1.277331,11.99977,1.277331
        M14.688662,17.521025l-2.79062-14.092453H11.75939L8.968451,17.521025c-0.34845,1.759186,1.028516,3.393261,2.860187,3.393261
        C13.659826,20.914286,15.037272,19.28021,14.688662,17.521025z M11.057564,19.522667
        c-0.524104-0.342857-0.894154-0.89444-1.015809-1.496998c-0.12658-0.602091,0.002701-1.250811,0.352262-1.763695l0.08354,0.05471
        c-0.051775,0.320471-0.075915,0.599606-0.075281,0.864594c0.017154,0.267788,0.05098,0.512104,0.107043,0.753315
        c0.068295,0.238104,0.145163,0.466879,0.246491,0.703739c0.097991,0.238724,0.206941,0.494078,0.357977,0.803358
        L11.057564,19.522667z"/>
		</svg>
	)
}

export default Logo;

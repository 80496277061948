import React from 'react';

import './index.scss';

const Contents = props => {
	if (props.platform) {

		const isYouTube = props.platform === "youtube";
		const isVimeo = props.platform === "vimeo";

		const autoplay = props.autoplay ? 'autoplay=1' : '';

		const controls = props.controls === 'hide' && isYouTube ? `controls=0&showinfo=0&loop=1&playlist=${props.src}`
			: props.controls === 'hide' && isVimeo ? 'background=1'
				: ''

		const params = [autoplay, controls].filter(Boolean).join('&');

		const src = isYouTube ? `https://www.youtube.com/embed/${props.src}?${params}`
			: isVimeo ? `https://player.vimeo.com/video/${props.src}?${params}`
				: '';

		if (props.src) {
			return (
				<iframe
					className={`iframe`}
					title={props.source}
					width="16"
					height="9"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					src={src} />
			)
		}
		return null;
	}

	return (
		<video poster={props.poster} {...props.htmlVideoAttributes} >
			{props.src && <source src={props.src} type="video/mp4" />}
		</video>
	)
}

const Video = props => {

	const classes = props.className ? `video ${props.className}` : 'video';

	return (
		<div className={classes}>
			<Contents {...props} />
		</div>
	)
}

export default Video;

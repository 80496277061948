import React from 'react';
import { Link } from 'react-router-dom';
import formatPermalink from '../../../utilities/formatPermalink';

const Hyperlink = props => {
	const {
		linkType,
		customUrl,
		label,
		pageUrl,
		children
	} = props;

	if (linkType === 'custom') {
		return (
			<a href={customUrl} target="_blank" rel="noopener noreferrer">{label}{children}</a>
		)
	}

	return <Link to={formatPermalink(pageUrl)}>{label}{children}</Link>;
}

export default Hyperlink;

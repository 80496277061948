import React, { Component } from 'react';
import { connect } from 'react-redux';
import SiteWidth from '../../layout/SiteWidth';

const mapStateToProps = state => ({
  width: state.browser.windowWidth,
  height: state.browser.windowHeight
});

const mapDispatchToProps = dispatch => ({
  setWindowHeight: size => dispatch({ type: 'SET_WINDOW_HEIGHT', payload: size }),
  setWindowContentWidths: widths => dispatch({ type: 'SET_WINDOW_CONTENT_WIDTHS', payload: widths })
});

class MeasureWindow extends Component {

  constructor(props) {
    super(props);
    this.windowWidthRef = React.createRef();
    this.frameWidthRef = React.createRef();
    this.contentWidthRef = React.createRef();

    this.state = {
      isMounted: false
    }
  }

  updateSizes = () => {

    if (this.state.isMounted) {
      this.props.setWindowHeight(window.innerHeight);
      const widthMeasurments = {
        window: this.windowWidthRef.current.offsetWidth,
        frame: this.frameWidthRef.current.offsetWidth,
        content: this.contentWidthRef.current.offsetWidth,
      }
      this.props.setWindowContentWidths(widthMeasurments)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSizes);

    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        this.updateSizes();
      }, 500);
    })

    this.updateSizes();

    this.setState({
      isMounted: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isMounted !== this.state.isMounted) {
      this.updateSizes();
    }
  }

  render() {

    if (this.state.isMounted) {
      return (
        <div className="measurements" ref={this.windowWidthRef}>
          <SiteWidth ref={this.frameWidthRef}>
            <div ref={this.contentWidthRef}></div>
          </SiteWidth>
        </div>
      );
    }

    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureWindow);

import React from 'react';

import { Link } from 'react-router-dom';

import LogoMark from '../../graphics/LogoMark';
import SocialMediaLinks from '../../elements/SocialMediaLinks';

import './index.scss';

const baseName = 'sidebar'

const Sidebar = props => {

  return (
    <aside className={baseName}>
      <Link to="/">
				<LogoMark />
			</Link>
      <SocialMediaLinks />
    </aside>
  )
};

export default Sidebar;

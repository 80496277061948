import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import smoothScroll from '../../../utilities/smoothScroll';
import isLocalPath from '../../../utilities/isLocalPath';
import formatPermalink from '../../../utilities/formatPermalink';
import Arrow from '../../graphics/Arrow';

import './index.scss';

class Button extends Component {
	render() {
		const type = this.props.type ? ` type-${this.props.type}` : ' type-primary';

		const baseClasses = `btn${type}`
		const classes = this.props.className ? `${baseClasses} ${this.props.className}` : baseClasses;

		const buttonProps = {
			className: classes,
			style: this.props.style,
			onClick: this.props.onClick,
			target: this.props.target,
			rel: this.props.rel
		}

		const Contents = () => {
			if (this.props.children) {
				const children = this.props.children.split(' ');
				return (
					<span className="btn-content">
						{children.map((word, i) => {
							return (
								<span key={i}>
									{word}&nbsp;
									{(i === children.length - 1 && this.props.arrow) &&
										<Arrow />
									}
								</span>
							)
						})}
					</span>
				)
			}
		}

		if (this.props.url) {
			if (isLocalPath(this.props.url) && !this.props.forceLocalReload) {
				const formattedUrl = formatPermalink(this.props.url);
				const shouldUseScroll = this.props.url.lastIndexOf('#', 0) === 0 ? true : false;
				const clickFunction = shouldUseScroll ? () => smoothScroll.scrollTo(this.props.url.substring(1)) : null;

				return (
					<Link {...buttonProps} to={formattedUrl} onClick={clickFunction}>
						<Contents />
					</Link>
				)

			} else {
				return (
					<a {...buttonProps} href={this.props.url} target={!this.props.forceLocalReload ? '_blank' : ''} rel="noopener noreferrer">
						<Contents />
					</a>
				)
			}
		}

		return (
			<button {...buttonProps}>
				<Contents />
			</button>
		)
	}
}

export default Button;

import React from 'react';

const Quote = props => {
	return (
		<svg className="graphic quote" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path className="fill" d="M9.100083,4.447922v1.979897c-2.389523,0.676942-3.60003,2.377468-3.60003,5.06039v0.83986v0.5h0.5h3.10003
			v6.76866h-8.60008V14.21774C0.500003,8.231844,3.314636,5.031151,9.100083,4.447922 M9.600083,3.90327
			c-6.40049,0.45527-9.60008,3.89343-9.60008,10.31447v5.878989h9.60008v-7.76866h-3.60003v-0.83986
			c0-2.57206,1.20001-4.12911,3.60003-4.67169V3.90327L9.600083,3.90327z"/>
			<path className="fill" d="M23.499996,4.447924v1.980493c-2.357286,0.675143-3.59981,2.416719-3.59981,5.059793v0.83986v0.5h0.5h3.09981
			v6.76866h-8.599871V14.21774C14.900126,8.231854,17.71468,5.031153,23.499996,4.447924 M23.999996,3.90327
			c-6.40027,0.45527-9.599871,3.89343-9.599871,10.31447v5.878989h9.599871v-7.76866h-3.59981v-0.83986
			c0-2.57206,1.200008-4.12911,3.59981-4.67169V3.90327L23.999996,3.90327z"/>
		</svg>
	)
}

export default Quote;
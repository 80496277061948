import React from 'react';

const Twitter = props => {

	const colors = {
		primary: '#003057',
		secondary: '#ffffff'
	}

	return (
		<svg className="graphic twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
			<path className="fill" fill={colors.primary} d="M0,0v24h24V0H0z"/>
			<path className="fill" fill={colors.secondary} d="M16.911249,12.22046c-0.97882,3.96564-7.547,5.647891-10.56854,1.53076
			c1.268946,1.177774,3.246671,1.124897,4.45087-0.119c-0.74933,0.10962-1.29474-0.62536-0.37384-1.02228
			c-0.597601,0.106347-1.19527-0.186512-1.477419-0.72394c0.216523-0.218736,0.514895-0.336579,0.82245-0.32483
			c-0.90649-0.21424-1.24139-0.65735-1.34393-1.195189c0.240477-0.066027,0.48944-0.095897,0.73871-0.08863
			C8.541667,10.030873,8.135392,9.433845,8.13287,8.76862c1.057115,0.378185,2.086014,0.831027,3.07892,1.3551
			c0.333513,0.202889,0.639047,0.448544,0.90882,0.73071c0.313873-1.136358,0.969503-2.148754,1.87805-2.9
			c-0.020783,0.134796-0.083779,0.259528-0.17993,0.35626c0.261765-0.237701,0.588239-0.392289,0.938-0.44415
			c-0.03961,0.25677-0.40911,0.4013-0.63256,0.48535c0.16925-0.05243,1.06823-0.45349,1.16589-0.225
			c0.11584,0.25971-0.61993,0.3794-0.74536,0.42432c-0.0932,0.03113-0.18671,0.06579-0.27887,0.103
			c1.210332-0.014781,2.268315,0.813594,2.544311,1.99213c0.0224,0.084,0.045231,0.177,0.06567,0.275
			c0.475695,0.109856,0.974619,0.054448,1.414619-0.1571c-0.254887,0.484328-0.759872,0.784783-1.307131,0.77771
			c0.435966,0.152747,0.901947,0.199915,1.35968,0.13763C17.968559,12.061739,17.444828,12.259596,16.911249,12.22046z"/>
		</svg>
	)
}

export default Twitter;

import React from 'react';

import SiteWidth from '../../layout/SiteWidth';

import './index.scss';

const ImageBlock = props => {

  const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
  const fullWidth = props.fullWidth ? 'full-width' : '';


  return (
    <div id={props.id} className={`image-block ${margin} ${fullWidth}`}>
      <SiteWidth>
        <img src={props.image.url} alt={props.image.alt} />
      </SiteWidth>
    </div>
  )
}

export default ImageBlock;

import React from 'react';

import './index.scss';

import SiteWidth from '../../layout/SiteWidth';

import HtmlBlock from '../../utilities/HtmlBlock';
import Quote from '../../graphics/Quote';

const Blockquote = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (
		<div id={props.id} className={`blockquote${margin}`}>
      <SiteWidth>
  			<div className="blockquote-wrap row">
            <div className="quote-icon">
  					   <Quote />
             </div>
             <div className="content-wrap">
    					 <HtmlBlock className="quote-content" content={props.quote} />
               { props.optionalQuoteeLabel &&
                <cite>
                  <HtmlBlock content={`&mdash;&nbsp;${props.optionalQuoteeLabel}`} />
                </cite>
               }
            </div>
  			</div>
      </SiteWidth>
		</div>
	);
}

export default Blockquote;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoMark from '../../graphics/LogoMark';
import LogoName from '../../graphics/LogoName';
import SetModal from '../../modals/SetModal';
import Hamburger from '../../graphics/Hamburger';
import Phone from '../../graphics/Phone';
import Menu from '../../modals/Menu';

import './index.scss';

const mapStateToProps = state => ({
	header: state.api.header,
	searchParams: state.browser.searchParams,
});

const baseName = 'header-main';

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			navOpen: false
		}
	}

	render() {
		const { header, searchParams: { modal } } = this.props;
		const menuOpen = modal === 'menu';

		return (
			<header className={baseName}>
				<div className={`${baseName}__wrap`}>
					<Link to="/" className={`${baseName}__brand`}>
						<LogoMark />
						<LogoName />
					</Link>
					{(header && header.ctaUrl) &&
						<span className={`${baseName}__cta`}>
							<a href={header.ctaUrl} target="_blank" rel="noopener noreferrer">{header.ctaLabel}</a>
							<Phone />
						</span>
					}
					<SetModal modal="menu" className={`${baseName}__menu-button`}>
						<span>{menuOpen ? 'Close' : 'Menu'}</span>
						<Hamburger className={menuOpen ? 'active' : ''} />
					</SetModal>
				</div>
				<Menu />
			</header>
		);
	}
}

export default connect(mapStateToProps)(Header);

const defaultState = {
	scrollPos: 0,
	windowWidth: 0,
	contentWidth: 0,
	windowHeight: 0,
	searchParams: {},
}

export default (state = defaultState, action) => {

	switch (action.type) {

		case 'UPDATE_SCROLL':

			return {
				...state,
				scrollPos: action.payload
			};

		case 'SET_WINDOW_HEIGHT':

			return {
				...state,
				windowHeight: action.payload
			}

		case 'SET_WINDOW_CONTENT_WIDTHS':

			return {
				...state,
				windowWidth: action.payload.window,
				frameWidth: action.payload.frame,
				contentWidth: action.payload.content
			}

		case 'SET_SEARCH_PARAMS':

			return {
				...state,
				searchParams: action.payload
			}

		default:
		//
	}

	return state;
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import HtmlBlock from '../../utilities/HtmlBlock';
import Arrow from '../../graphics/Arrow'

import './index.scss';
import SiteWidth from '../../layout/SiteWidth';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth,
	frameWidth: state.browser.frameWidth,
	contentWidth: state.browser.contentWidth
})

const SlickArrow = (props) => {
	const { className, onClick } = props;

	return (
		<span onClick={onClick} className={className}>
			<Arrow />
		</span >
	);
}

class ImageSlider extends Component {

	constructor(props) {
		super(props);

		this.sliderWrapperRef = React.createRef();

		this.state = {
			imageSlider: null,
			contentSlider: null,
			slideCount: 0,
			sliderWrapperHeight: null,
			sliderMargin: null,
			currentIndex: 0
		};
	}

	componentDidMount() {
		this.setState({
			imageSlider: this.slider1,
			contentSlider: this.slider2,
			sliderWrapperHeight: this.sliderWrapperRef.current.offsetHeight,
			slideCount: this.props.slides.length
		});
	}

	componentDidUpdate() {
		if (this.state.sliderWrapperHeight !== this.sliderWrapperRef.current.offsetHeight) {
			this.setState({ sliderWrapperHeight: this.sliderWrapperRef.current.offsetHeight });
		}

		const marginOffset = this.props.contentWidth !== this.props.windowWidth
			? (this.props.windowWidth - this.props.contentWidth) / 2
			: 0;

		if (this.state.sliderMargin !== marginOffset) {
			this.setState({ sliderMargin: marginOffset });
		}
	}

	updateCurrentIndex = (oldIndex, newIndex) => {
		if (this.state.imageSlider && this.state.contentSlider) {
			this.state.imageSlider.slickGoTo(newIndex);
			this.state.contentSlider.slickGoTo(newIndex);

			this.setState({
				currentIndex: newIndex
			})
		}
	}

	manuallyUpdateIndex = (direction) => {
		const prevIndex = this.state.currentIndex - 1 <= this.state.slideCount - 1 ? this.state.currentIndex - 1 : this.state.slideCount;
		const nextIndex = this.state.currentIndex + 1 <= this.state.slideCount - 1 ? this.state.currentIndex + 1 : 0;
		const newIndex = direction === 'prev' ? prevIndex : nextIndex;

		this.updateCurrentIndex(null, newIndex);
	}

	render() {

		const contentSliderSettings = {
			ref: slider => (this.slider1 = slider),
			prevArrow: <SlickArrow className="slick-prev" />,
			nextArrow: <SlickArrow className="slick-next" />,
			beforeChange: this.updateCurrentIndex,
			focusOnSelect: false,
		}

		const imageSliderSettings = {
			ref: slider => (this.slider2 = slider),
			arrows: false,
			swipeToSlide: true,
			focusOnSelect: true,
		}

		const sliderHeight = this.state.sliderWrapperHeight;
		const { slides, id } = this.props;
		const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';

		if (slides && slides.length > 0) {
			return (
				<div className={`slider-wrapper ${margin}`}>
					<div id={id} className="slider-contents" style={{ marginLeft: `${this.state.sliderMargin}px` }} ref={this.sliderWrapperRef}>
						<div className="content-slider">
							<div className="wrap">
								<Slider {...contentSliderSettings}>
									{slides.map((slide, i) =>
										<div className="content" key={`content-${i}`} >
											<h3 className="slide-title">{slide.title}</h3>
											<HtmlBlock content={slide.content} />
										</div>
									)}
								</Slider>
							</div>
							<aside className="white-bg" />
						</div>
						<div className="image-slider">
							<Slider {...imageSliderSettings}>
								{slides.map((slide, i) =>
									<div className="image-wrapper" key={`image-${i}`}>
										<div className="slide-bg-image" style={{ height: `${sliderHeight}px`, background: `url(${slide.image.url}) center center / cover no-repeat` }}>

										</div>
									</div>
								)}
							</Slider>
						</div>
					</div>
					<SiteWidth>
						<nav className="mobile-slider-nav">
							<SlickArrow className="slick-prev" onClick={() => this.manuallyUpdateIndex('prev')} />
							<h4 className="slide-count">
								Viewing {this.state.currentIndex + 1} of {this.state.slideCount}
							</h4>
							<SlickArrow className="slick-next" onClick={() => this.manuallyUpdateIndex('next')} />
						</nav>
					</SiteWidth>
				</div>
			)
		}

		return null;
	}
}

export default connect(mapStateToProps)(ImageSlider);

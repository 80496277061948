import React from 'react';

import './index.scss';

const Arrow = props => {
	return (
		<svg className="graphic arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<polyline className="stroke" points="16.025074,18.992958 23,12 16.025074,5.007042 "/>
			<line className="stroke" x1="23" y1="12" x2="1" y2="12"/>
		</svg>
	)
}

export default Arrow;
import React from 'react';

import SiteWidth from '../../layout/SiteWidth';

import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';
import Arrow from '../../graphics/Arrow';

import './index.scss';

const Content = props => {

	let classes = 'content-block';
	classes += props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (

		<div id={props.id} className={classes}>
      <SiteWidth>
  			<div className="row">
  				{props.row &&
  					props.row.map((column, i) => {
  						return (
  							<div className={`column ${column.width}`} key={i}>
  								<HtmlBlock content={column.content} />
  								{column.ctas &&
                    <ul className="cta-list">
                      {column.ctas.map((cta, i) => {
                        return (
                          <li key={i}>
                            <Button
                              type={cta.button.buttonType}
                              url={cta.button.linkType === 'page' ? cta.button.pageUrl : cta.button.customUrl}>
                              {cta.button.label}
                              {cta.button.arrow && <Arrow />}
                            </Button>
                          </li>
                        )
                      })}
                    </ul>
                  }
  							</div>
  						)
  					})
  				}
  			</div>
      </SiteWidth>
		</div>
	)
}

export default Content;

import React from 'react';
import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';
import SiteWidth from '../../layout/SiteWidth';

import './index.scss';

const ServiceCallout = (props) => {

	let classes = 'service-callout';
	classes += props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	const { services } = props;

	return services.map((mappedService, index) => {
		const { icon, primaryCta, secondaryTextCta, service } = mappedService;

		

		return (
			<div key={`service-${index}`} id={`service-${index}`} className={classes}>
				<SiteWidth>
					<div className="row">
						<img className="icon" src={icon.url} alt={icon.alt} />

						<div className="content">
							<div className="title">
								<h4>{service.title}</h4>
							</div>
							<div className="description">
								<HtmlBlock content={service.description} />
								<Button
									className="mobile-learn-more-link"
									type="text"
									url={secondaryTextCta.button.linkType === 'page' ? secondaryTextCta.button.pageUrl : secondaryTextCta.button.customUrl} arrow>
									{secondaryTextCta.button.label}
								</Button>
							</div>
						</div>
						<ul className="cta-list">
							<li className="cta">
								<Button
									type="secondary"
									url={primaryCta.button.linkType === 'page' ? primaryCta.button.pageUrl : primaryCta.button.customUrl} arrow>
									{primaryCta.button.label}
								</Button>
							</li>
							<li className="secondary-text-cta">
								<Button
									type="text"
									url={secondaryTextCta.button.linkType === 'page' ? secondaryTextCta.button.pageUrl : secondaryTextCta.button.customUrl} arrow>
									{secondaryTextCta.button.label}
								</Button>
							</li>
						</ul>
					</div>
				</SiteWidth>
			</div>
		)
	});
}

export default ServiceCallout;

import React from 'react';

import './index.scss';

const Raindrops = props => {
	const { color, className } = props;
	
	return (
		<svg className={`graphic raindrops ${className || ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 689 1177">
			<path className="fill" fill={color || '#ffffff'} d="M689,267.841187l-91.33783,207.603699l-0.036133-0.081299l-14.128113,32.111877
		    c-0.010193,0.060364,0.000305,0.123352-0.009155,0.184937l-23.48175,53.372131l-14.128052,32.111938l-18.894165,43.20575
		    L527,636.38446l-2.419617,5.464172L514.893433,664l-0.102295-0.044861L513,668
		    c0.244385-1.019897,0.562317-2.025208,1.033813-2.983093h-0.070251l0.52887-1.192688l-0.236145-0.103516l-0.923706,2.098938
		    h0.069336c-0.464905,0.951538-0.778442,1.950317-1.019409,2.963501l-44.128723,101.515747l12.322815,26.735229h-0.066406
		    c0.993652,1.94281,1.492493,4.059021,1.489807,6.171326c-0.005127,3.520935-1.40271,7.033997-4.223267,9.72583
		    c-5.686584,5.424988-14.906067,5.424988-20.592346,0c-2.316711-2.211426-3.655579-4.972534-4.055603-7.838257L321.89032,1107
		    L320,1106.169189L536,610v0.837952L687.117493,267L689,267.841187z M245.544556,122L231,155.935669
		    c0.366699,2.91217,1.582581,5.728149,3.771484,7.973083c5.322571,5.455017,13.952576,5.455017,19.275513,0
		    c4.224487-4.331238,5.039062-10.774719,2.558716-15.985168h0.062195L245.544556,122z M73.186646,253.904236
		    c5.685791,5.460999,14.904602,5.460999,20.590698,0c4.512634-4.333679,5.382812-10.782776,2.733276-15.997742h0.066345
		    L84.20575,210.891479L85,209.080566L84.964294,209l-0.794861,1.812195l-0.182251-0.39801l0.461243-1.050598l0.036072,0.081299L176,1
		    h-2.247864L0,397.169861L1.889404,398l30.920532-70.85553c0.223145-1.345459,0.627197-2.668701,1.237427-3.919739h-0.069214
		    l12.662231-28.803284l0.268066,0.611084l22.12146-50.386292C69.154663,248.010437,70.50592,251.329651,73.186646,253.904236z
		     M354.073242,578.339294L90.888672,1177L89,1176.169556L536,159v5.129639L369.26416,543.760437l12.312866,27.033752h-0.066467
		    c2.649536,5.244141,1.779602,11.729309-2.733582,16.087158c-5.686829,5.491516-14.907043,5.491516-20.593262,0
		    C355.70575,584.48877,354.363647,581.455811,354.073242,578.339294z M368.436646,543.721497l-4.483276,10.192383l4.558533-10.021484
		    L368.436646,543.721497z M207.203735,366.923889c5.681885,5.434814,14.894287,5.434814,20.576538,0
		    c4.50946-4.312866,5.379089-10.730957,2.731323-15.920898h0.066406l-12.106262-26.327515l32.548096-73.972351
		    C251.016602,250.802246,251.001221,250.900879,251,251l15-33.525024L265.339294,216l-12.947388,28.899963h0.070312
		    c-0.40863,0.821167-0.712402,1.677063-0.948975,2.546631c0.237366-1.091553,0.594177-2.162354,1.092529-3.183899h-0.068787
		    l12.668579-28.841064l0.646484,1.471985L361,0h-2.387024L73,650.168823L74.887695,651l128.307983-291.606567
		    C203.656982,362.149353,204.976074,364.793701,207.203735,366.923889z M429.885681,1023L536,776.483521V772l-108,250.168396
		    L429.885681,1023z M124,816.16925L481.798035,1H484l-49.812683,113.385742l0.05426,0.119873L434.466248,114L435,115.202393
		    l-0.218811,0.49292l11.79541,26.007812h-0.066406c2.650269,5.268799,1.779846,11.782715-2.733704,16.159912
		    c-5.687927,5.51593-14.909119,5.51593-20.596436,0c-2.588379-2.511108-3.934631-5.72229-4.139465-8.999268L125.889526,817
		    L124,816.16925z M420.556763,143.025879h0.068909c-0.417664,0.855469-0.734131,1.744385-0.961121,2.651367l13.90979-30.710205
		    l-0.337341-0.767578L420.556763,143.025879z M174.358582,563.616943L421.757446,1H424L175.909058,565.1297l-0.914978-2.077637
		    l-0.441895,1.003479L175,565.070007l-12.900269,29.265503l10.550171,24.438904h-0.062988
		    c2.513794,5.250244,1.688171,11.741272-2.592896,16.103149c-5.395142,5.496582-14.141541,5.496582-19.53595,0
		    c-1.411865-1.438721-2.410461-3.116089-3.056519-4.898376L103.89032,729L102,728.169739l44.088318-100.262512
		    c-0.029846-0.237793-0.072754-0.473999-0.088318-0.713501L160.958191,593L161,593.095215
		    c-0.269409,0.766602-0.474121,1.548462-0.631226,2.336426l0.812805-1.84845L161,593.171753
		    c0.172791-0.48877,0.256104-0.998352,0.486511-1.468872h-0.068848L174.086243,563L174.358582,563.616943z M161.440247,594.169312
		    l-0.158936,0.361328l0.221619,0.517822l0.160706-0.372864L161.440247,594.169312z M160.126831,597.15564
		    c-0.027405,0.377136-0.028137,0.755249-0.021118,1.134094l0.069458-0.161194c0.041199-0.520142,0.110779-1.037476,0.206482-1.552246
		    L160.126831,597.15564z M230.486755,152.540771l12.545471-28.59082l-11.939087,27.431396
		    c-0.151978,1.204956-0.105286,2.416382,0.048584,3.618652l14.410828-33.105347l0.013123,0.029907L296.76416,5.264648
		    C296.950745,3.801025,297.36145,2.361084,298,1h-1.62561L58,544.169128L59.890137,545L230.53595,156.171509
		    C230.380249,154.96521,230.333008,153.749756,230.486755,152.540771z M298.959778,1H298.3078
		    c-0.654968,1.276611-1.076233,2.627197-1.267578,4L298.959778,1z M358.443542,756.460938
		    c5.504333,5.500977,14.429565,5.500977,19.934753,0c4.368958-4.365479,5.21106-10.861694,2.646057-16.114807h0.064453
		    l-12.254272-27.842163l-0.013306,0.029846l0.003601-0.051941l0.009705,0.022095L536,332.127258V327l-372,846.169922L165.889038,1174
		    l188.574951-428.804749C354.065369,749.22113,355.352722,753.372681,358.443542,756.460938z"/>
		</svg>
	)
}

export default Raindrops;

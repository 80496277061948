import React from 'react';
import { connect } from 'react-redux';
import SocialMediaLinks from '../../elements/SocialMediaLinks';

import HtmlBlock from '../../utilities/HtmlBlock';

import './index.scss';

const mapState = state => ({
	footer: state.api.footer
})

const Footer = props => {
	if (props.footer) {
		return (
			<footer className="footer-main">
				<SocialMediaLinks />
				<HtmlBlock content={props.footer.leftContent} />
				<HtmlBlock content={props.footer.rightContent} />
			</footer>
		);
	}

	return null;
}

export default connect(mapState)(Footer);

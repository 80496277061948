import React from 'react';

const Phone = props => {
	return (
		<svg className="graphic phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path className="fill" fill={props.color} d="M8.06869,6.57081a.66065.66065,0,0,0-.46982.1977,2.69861,2.69861,0,0,0-.99451,1.80436c-.22179,1.84108.63705,3.54255,2.96424,5.87476,2.7604,2.76648,5.04915,2.98153,5.68486,2.98153h0c.10486,0,.16569-.00562.17982-.00708a2.73584,2.73584,0,0,0,1.80208-.99743.639.639,0,0,0,.02247-.89705L15.492,13.75789a.59582.59582,0,0,0-.42471-.17235.66007.66007,0,0,0-.46955.19724l-.50844.50882a.99384.99384,0,0,1-1.20391.15646,8.88626,8.88626,0,0,1-1.8951-1.4228A8.71523,8.71523,0,0,1,9.576,11.12689a.99984.99984,0,0,1,.15472-1.21151l.5052-.5056a.63712.63712,0,0,0,.02309-.89645L8.49313,6.74365A.594.594,0,0,0,8.06869,6.57081ZM15.25348,18h0a6.18382,6.18382,0,0,1-2.25426-.49161,11.61683,11.61683,0,0,1-3.8333-2.65711c-2.46634-2.47172-3.37234-4.3099-3.1272-6.34474a3.26668,3.26668,0,0,1,1.15683-2.141A1.22654,1.22654,0,0,1,8.06869,6a1.15931,1.15931,0,0,1,.8272.34l1.76618,1.77a1.2095,1.2095,0,0,1-.02358,1.7035l-.50534.50576a.428.428,0,0,0-.0659.51872,7.73314,7.73314,0,0,0,3.10461,3.11665.42531.42531,0,0,0,.515-.067l.5083-.5087a1.22527,1.22527,0,0,1,.87217-.36429,1.16159,1.16159,0,0,1,.82712.33917L17.661,15.12432a1.21163,1.21163,0,0,1-.02324,1.70441l-.00253.00251a3.28585,3.28585,0,0,1-2.1365,1.15786l-.00319.00037A2.32854,2.32854,0,0,1,15.25348,18Z" />
		</svg>
	)
}

export default Phone;

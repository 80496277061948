import React from 'react';

const Facebook = props => {

	const colors = {
		primary: '#003057',
		secondary: '#ffffff'
	}

	return (
		<svg className="graphic facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
			<path className="fill" fill={colors.primary} d="M0,0v24h24V0H0z"/>
			<path className="fill" fill={colors.secondary} d="M14.80005,8.10321h-1.35474c-0.269775,0.017936-0.492377,0.217879-0.53906,0.48419V9.96869h1.891171 c-0.07574,1.0592-0.232,2.02789-0.232,2.02789H12.89685V18H10.4104v-6.00354H9.2V9.9762H10.4104V8.324 c0-0.30181-0.06146-2.32415,2.54767-2.324h1.842L14.80005,8.10321z"/>
		</svg>
	)
}

export default Facebook;
import React from 'react';
import Button from '../../controls/Button';

import './index.scss';

const baseName = 'button-group';

const ButtonGroup = ({ buttons }) => {
	if (buttons && buttons.length > 0) {
		return (
			<ul className={baseName}>
				{buttons && buttons.map((button, i) => {
					return (
						<li key={i}>
							<Button type={button.buttonType} arrow={button.arrow} url={button.pageUrl}>{button.label}</Button>
						</li>
					)
				})}
			</ul>
		)
	}
	return null;
}

export default ButtonGroup;

///////////////////////////////////////////////////////
// Takes a modal component and
// a slug to match against a 'modal' URL param
///////////////////////////////////////////////////////

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import canUseDom from '../../../utilities/canUseDom';

import './index.scss';

const modalWrap = (PassedComponent, modalSlug) => {

	const mapStateToProps = state => ({
		searchParams: state.browser.searchParams,
		windowHeight: state.browser.windowHeight
	});

	class ModalWrap extends Component {

		constructor(props) {
			super(props);

			this.bindEsc = (event) => {
				if (event.keyCode === 27) {
					this.props.history.push(this.props.location.pathname);
				}
			}

			this.state = {
				open: false,
				domElement: null
			}
		}

		closeModal = () => {
			this.setState({ open: false });
		}

		openModal = () => {
			this.setState({ open: true });
		}

		setOpenStatus = () => {

			// Slug can come from either a HOC or from a prop
			const slug = this.props.modalSlug ? this.props.modalSlug : modalSlug;

			if (this.props.searchParams.modal === slug) {
				this.openModal();
			} else {
				this.closeModal();
			}
		}

		componentDidMount() {
			if (canUseDom) {
				document.addEventListener('keydown', this.bindEsc, false);
			}

			// Slug can come from either a HOC or from a prop
			const slug = this.props.modalSlug ? this.props.modalSlug : modalSlug;

			if (this.props.modal === slug) {
				this.openModal();
			}
		}

		componentWillUnmount() {
			if (canUseDom) {
				document.removeEventListener('keydown', this.bindEsc, false);
			}
		}

		componentDidUpdate(prevProps, prevState) {
			if (prevProps.searchParams.modal !== this.props.searchParams.modal) {
				this.setOpenStatus();
			}
		}

		render() {

			// Slug can come from either a HOC or from a prop
			const slug = this.props.modalSlug ? this.props.modalSlug : modalSlug;

			return (
				<div className={`modal${this.state.open ? ' open' : ''}`}>
					<PassedComponent id={`modal-${slug}`} {...this.props} isOpen={this.state.open} />
				</div>
			)
		}
	}

	return withRouter(connect(mapStateToProps)(ModalWrap));
}

export default modalWrap;

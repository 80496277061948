import React from 'react';
import { Link } from 'react-router-dom';


import './index.scss';
import logoSrc from '../../graphics/Landing/soak-logo.svg';
import iconSprinkler from '../../graphics/Landing/icon-sprinkler.svg';
import runningSprinkler from '../../graphics/Landing/running-sprinkler.jpg';
import sprinklers from '../../graphics/Landing/sprinklers.jpg';

const Landing = (props) => {

	const { data } = props;

	if (data) {
		// const { acf } = data;

		return (
			<article className="landing">
				
				<header className="landing-header">
					<div className="o-centerContainer">
						<Link to="/">
							<img src={logoSrc} alt="Soak Irrigation"/>
						</Link>
					</div>
				</header>
				<section className="landing-hero">
					<div className="o-centerContainer">
						<h1>Do you want a soft, bright green yard this summer?</h1>
						<a href="/get-a-quote#Form-0" className="c-button">Request a Quote</a>
					</div>
				</section>
				<section className="landing-text landing-theme--green">
					<div className="landing-text-inner o-centerContainer">
						<h2>Make the most out of your home. </h2>
						<p><strong>Picture it: your kids playing outside on a hot summer day, rolling around in a cool, lush field of grass, or playing joyfully in the sprinklers from your irrigation system. Or maybe it’s a warm summer evening hanging outside barefoot in your yard with wine, music, and good friends.</strong> </p>
						<p>The home you’ve always wanted isn’t complete without your dream yard to go with it. A healthy, green lawn will bring vibrancy and energy to your environment, and will make your home feel far more inviting!</p>
						<p>We know that it’s exhausting and laborious to drag a hose across your lawn, standing in the sweltering sun for what feels like forever. You don’t want to do the unnecessary work, but you also don’t want to have a brittle, pokey, yellow yard that’s an eyesore and you don’t enjoy using. </p>
					</div>
				</section>

				<section className="landing-split-icon">
					<div className="landing-split-inner o-centerContainer">
						<div className="landing-split-image">
							<img src={iconSprinkler} alt="Sprinkler Icon"/>
						</div>
						<div className="landing-split-content">
							<h3>What’s worse? Hosing your yard consumes significantly more water than an irrigation system, which isn’t good for your water bill or your grass.</h3>
						</div>
					</div>
				</section>

				<section className="landing-text landing-theme--blue-drops">
					<div className="landing-text-inner o-centerContainer">
						<h2>The Easy Solution: </h2>
						<h3>A team of your reliable local handymen, just like the ones at Soak. </h3>
						<p>Known for our efficiency, innovative thinking, and commendable customer service, Soak is the most dependable irrigation solution on the market.</p>
						<a href="/get-a-quote#Form-0" className="c-button c-button--green">Request a Quote</a>
					</div>
				</section>

				<section className="landing-text landing-theme--orange-spray">
					<div className="o-centerContainer">
						<div className="landing-text-inner">
							<h2>Affordable. <br/>Cost-Effective. <br/>Results You Love.</h2>
							<p><strong>We make irrigation easy to understand and easy to install with top-notch options for every kind of yard and household.</strong></p>
							<p>We’ll optimize our design and installation to work best with your space and your budget for the best results possible.</p>
	  						<p>Make the most of your summer and your home with a greener yard for you and the family to laugh, play, and make memories that will last a lifetime.</p>

						</div>
					</div>
				</section>

				<section className="c-image-wrapper">
					<div className="c-image-showcase">
						<div className="c-image-showcase__item">
							<img src={runningSprinkler} alt="Running Sprinkler"/>
						</div>
						<div className="c-image-showcase__item">
							<img src={sprinklers} alt="Sprinklers"/>
						</div>
					</div>
				</section>

				<section className="landing-text landing-theme--multi-color">
					<div className="landing-text-inner o-centerContainer">
						<h2>BE PREPARED THIS SUMMER</h2>
						<h3>Before you know it, summer will be here, and you’ll be itching to get outside.</h3>
						<p>Reach out today, and get your new irrigation system installation set-up so that you’re set-up to have the best summer yet. </p>
						<a href="tel:+16167346626" className="c-button">Call 616-734-6626</a>
						<a href="/get-a-quote#Form-0" className="c-button">Request a Quote</a>
					</div>
				</section>

				<footer className="landing-footer">
					<div className="o-centerContainer landing-footer__inner">
						<div className="landing-footer-info">
							<a href="tel:+16167346626">616-734-6626</a>
							<address>1505 Steele Ave SW <br/>Grand Rapids, MI 49507</address>
							<a href="mailto:info@soakirrigation.com">info@soakirrigation.com</a>


						</div>
						<div className="landing-footer-social">
							
						</div>
						<div className="landing-footer-logo">
							<Link to="/">
								<img src={logoSrc} alt="Soak Irrigation"/>
							</Link>
						</div>
					</div>
				</footer>

				
			</article>
		);
	}

	return null;
};

export default Landing;

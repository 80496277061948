import React, { Component } from 'react';
import { connect } from 'react-redux';

import decodeHtmlEntities from '../../../utilities/decodeHtmlEntities';
import FrameMargin from '../../layout/FrameMargin';
import Raindrops from '../../graphics/Raindrops';

import './index.scss';

const mapStateToProps = state => ({
  windowWidth: state.browser.windowWidth
})

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decodedForm: '',
      formId: '',
      formUrl: '',
      backgroundColor: '',
      formType: '', // Add this to determine the form type
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.initializeFormData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formCode !== this.props.formCode) {
      this.initializeFormData();
    }
  }

  componentWillUnmount() {
    this.cleanupForm();
  }

  initializeFormData = () => {
    const decodedForm = decodeHtmlEntities(decodeHtmlEntities(this.props.formCode));
    
    // Determine form type
    if (decodedForm.includes('typeform')) {
      this.initializeTypeform(decodedForm);
    } else if (decodedForm.includes('gfiframe')) {
      this.initializeGravityForm(decodedForm);
    } else {
      this.setState({ formType: 'unknown', decodedForm });
    }
  }

  initializeTypeform = (decodedForm) => {
    const formUrlMatch = decodedForm.match(/data-url="(https:\/\/form\.typeform\.com\/to\/[^"]+)"/);
    const formUrl = formUrlMatch ? formUrlMatch[1] : '';
    const formIdMatch = formUrl.match(/\/to\/([^?]+)/);
    const formId = formIdMatch ? formIdMatch[1] : '';
    
    // Extract background color from the script
    const backgroundColorMatch = decodedForm.match(/backgroundColor:\s*['"]([^'"]+)['"]/);
    const backgroundColor = backgroundColorMatch ? backgroundColorMatch[1] : '';

    this.setState({ formType: 'typeform', decodedForm, formId, formUrl, backgroundColor }, () => {
      if (typeof window !== 'undefined') {
        this.loadTypeformEmbed();
      }
    });
  }

  initializeGravityForm = (decodedForm) => {
    const iframeSrcMatch = decodedForm.match(/src="([^"]+)"/);
    const formUrl = iframeSrcMatch ? iframeSrcMatch[1] : '';
    
    this.setState({ formType: 'gravityform', decodedForm, formUrl }, () => {
      if (typeof window !== 'undefined') {
        this.loadGravityFormEmbed();
      }
    });
  }

  loadTypeformEmbed = () => {
    if (this.state.formId && this.formRef.current) {
      this.cleanupForm();

      const script = document.createElement('script');
      script.src = 'https://embed.typeform.com/embed.js';
      script.async = true;
      script.onload = () => this.createTypeformEmbed();
      document.body.appendChild(script);
    }
  }

  createTypeformEmbed = () => {
    if (window.typeformEmbed && this.formRef.current) {
      window.typeformEmbed.makeWidget(
        this.formRef.current,
        this.state.formUrl,
        {
          hideHeaders: true,
          hideFooter: true,
          opacity: 100,
          backgroundColor: this.state.backgroundColor,
          onSubmit: () => {
            console.log('Form submitted');
          },
        }
      );
    }
  }

  loadGravityFormEmbed = () => {
    if (this.state.formUrl && this.formRef.current) {
      const script = document.createElement('script');
      script.src = '//soak.local/wp-content/plugins/gravity-forms-iframe-develop/assets/scripts/gfembed.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }

  cleanupForm = () => {
    if (typeof window !== 'undefined') {
      const typeformScripts = document.querySelectorAll('script[src^="https://embed.typeform.com/"]');
      typeformScripts.forEach(script => script.remove());

      const typeformIframe = document.querySelector('iframe[src^="https://form.typeform.com/"]');
      if (typeformIframe) {
        typeformIframe.remove();
      }

      if (window.typeformEmbed) {
        delete window.typeformEmbed;
      }
    }
  }

  render() {
    const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';

    return (
      <div id={this.props.id} className={`wufoo-form ${margin}`}>
        {this.props.useFrameBackground &&
          <FrameMargin side="left">
            <div className="bg-color">
              <Raindrops color="#1DCAD3" />
            </div>
          </FrameMargin>
        }
        <div className="html-block content">
          {this.state.formType === 'typeform' && (
            <div 
              ref={this.formRef}
              className="typeform-widget" 
              data-url={this.state.formUrl}
              data-hide-headers="true" 
              style={{ 
                width: '100%', 
                height: '500px',
                position: 'relative',
                zIndex: 1
              }}
            />
          )}
          {this.state.formType === 'gravityform' && (
            <iframe 
              src={this.state.formUrl} 
              width="100%" 
              height="500" 
              frameBorder="0" 
              className="gfiframe typeform-widget"
              ref={this.formRef}
            />
          )}
          {this.state.formType === 'unknown' && (
            <div dangerouslySetInnerHTML={{ __html: this.state.decodedForm }} />
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Form);
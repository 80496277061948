import React from 'react';

import MediumHero from '../../layout/MediumHero';
import FlexibleContent from '../../utilities/FlexibleContent';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';

import './index.scss';

const Default = props => {
	if (props.data) {
		return (
			<div>
				<Sidebar />
				<Header />
				<article className={`${props.slug} default-template`}>
					<MediumHero {...props.data.acf.MediumHero} />
					<FlexibleContent content={props.data.acf.blocks} />
				</article>
			</div>
		)
	}

	return null;
}

export default Default;

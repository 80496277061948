import React from 'react';
import SiteWidth from '../../layout/SiteWidth';
import HtmlBlock from '../../utilities/HtmlBlock';

import './index.scss';

const StatsGrid = props => {

  const margin = props.margin && props.margin !== 'none' ? `margin-${props.margin}` : '';
  return (
    <div id={props.id} className={`stats-grid ${margin}`}>
      <SiteWidth>
        {props.statsGridHeadline &&
          <HtmlBlock content={props.statsGridHeadline} />
        }
        <ul className="stats-list">
          {props.stats &&
            props.stats.map((stat, index) => {
              return (
                <li key={index} className="stat-item">
                  <hr />
                  <h3 className="stat-value">{stat.value}</h3>
                  <HtmlBlock content={stat.description} />
                </li>
              )
            })
          }
        </ul>
      </SiteWidth>
    </div>
  )
}

export default StatsGrid;

import React from 'react';

import LargeHero from '../../layout/LargeHero';
import FlexibleContent from '../../utilities/FlexibleContent';
import Sidebar from '../../layout/Sidebar';
import Header from '../../layout/Header';

import './index.scss';

const Home = (props) => {

	const { data } = props;

	if (data) {
		const { acf } = data;

		return (
			<div>
				<Sidebar />
				<Header />
				<article className="home">
					<LargeHero {...acf.LargeHero} />
					<FlexibleContent content={acf.blocks} />
				</article>
			</div>
		);
	}

	return null;
};

export default Home;

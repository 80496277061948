import React, { Component } from 'react';
import FrameMargin from '../../layout/FrameMargin';
import Raindrops from '../../graphics/Raindrops';

import './index.scss';

class Calendly extends Component {

	constructor(props) {
		super();

		this.state = {
			elementId: props.uniqueId.split(' ').join('')
		}
	}

	componentDidMount() {
		const { elementId } = this.state;
		const scriptElement = document.createElement('script');

		scriptElement.type = 'text/javascript';
		scriptElement.src = 'https://assets.calendly.com/assets/external/widget.js';
		document.getElementById(elementId).appendChild(scriptElement);
	}

	render() {
		const { elementId } = this.state;
		const { margin, url } = this.props;
		const componentMargin = margin && margin !== 'none' ? ` margin-${margin}` : '';

		return (
			<div id={elementId} className={`calendly-wrapper ${componentMargin}`} >
				<FrameMargin side="left">
					<div className="bg-color">
						<Raindrops />
					</div>
				</FrameMargin>
				<div className="calendly-inline-widget" data-url={url} style={{ minWidth: '320px', height: '800px' }}></div>
			</div >
		);
	}

}

export default Calendly;

import React from 'react';

import './index.scss';

const baseName = 'frame-margin';

const FrameMargin = ({ side, children }) => {

	const classes = [baseName];

	if (side === undefined) classes.push(`${baseName}--margin-both`);
	if (side === 'left') classes.push(`${baseName}--margin-left`);
	if (side === 'right') classes.push(`${baseName}--margin-right`);

	return (
		<div className={classes.join(' ')}>
			{children}
		</div>
	)
}

export default FrameMargin;

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'qs';

const mapStateToProps = state => ({
	searchParams: state.browser.searchParams,
})

const SetModal = ({ searchParams, modal, children, className }) => {
	const newParams = { ...searchParams };

	if (newParams.modal === modal) {
		delete newParams.modal;
	} else {
		newParams.modal = modal;
	}

	return (
		<Link className={className} to={{ search: queryString.stringify(newParams) }}>
			{children}
		</Link>
	)
}

export default connect(mapStateToProps)(SetModal);

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import modalWrap from '../modalWrap';
import SiteWidth from '../../layout/SiteWidth';
import GridBleed from '../../layout/GridBleed';
import Raindrops from '../../graphics/Raindrops';
import Hyperlink from '../../controls/Hyperlink';
import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';

import './index.scss';

const baseName = 'menu';

const mapStateToProps = state => ({
	megaMenu: state.api.megaMenu,
});

const Menu = ({ megaMenu }) => {
	if (megaMenu) {
		const {
			mainMenuLabel,
			mainMenu,
			secondaryLabel,
			secondaryMenu,
			ctaContent,
			enablePrimaryCta,
			primaryCta,
			enableSecondaryCta,
			secondaryCta,
		} = megaMenu;

		return (
			<div className={baseName}>
				<SiteWidth className={`${baseName}__wrap`}>
					<div className={`${baseName}__inner-wrap`}>
						<div className={`${baseName}__main-nav-wrap`}>
							<span className="label">{mainMenuLabel}</span>
							{(mainMenu && mainMenu.length > 0) &&
								<ul className={`${baseName}__main-nav`}>
									{mainMenu.map((item, i) => {
										const { hyperlink } = item;
										return (
											<li key={i}>
												<Hyperlink {...hyperlink} />
											</li>
										)
									})}
								</ul>
							}
						</div>
						<div className={`${baseName}__secondary-nav-wrap`}>
							<span className="label">{secondaryLabel}</span>
							{(secondaryMenu && secondaryMenu.length > 0) &&
								<Fragment>
									<ul className={`${baseName}__secondary-nav`}>
										{secondaryMenu.map((item, i) => {
											const { hyperlink } = item;
											return (
												<li key={i}>
													<Hyperlink {...hyperlink} />
												</li>
											)
										})}
									</ul>
									<hr className="small" />
									<HtmlBlock content={ctaContent} />
									{enablePrimaryCta &&
										<Button type="primary" url={primaryCta.hyperlink.linkType === 'page' ? primaryCta.hyperlink.pageUrl : primaryCta.hyperlink.customUrl} arrow>{primaryCta.hyperlink.label}</Button>
									}
									{enableSecondaryCta &&
										<Button type="text" url={secondaryCta.hyperlink.linkType === 'page' ? secondaryCta.hyperlink.pageUrl : secondaryCta.hyperlink.customUrl} arrow>{secondaryCta.hyperlink.label}</Button>
									}
								</Fragment>
							}
						</div>
						<Raindrops />
					</div>
				</SiteWidth>
				<GridBleed cols={7} />
			</div>
		);
	}

	return null;
}

export default modalWrap(connect(mapStateToProps)(Menu), 'menu');

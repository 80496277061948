import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';

import HtmlBlock from '../../utilities/HtmlBlock';
import Quote from '../../graphics/Quote';
import SiteWidth from '../../layout/SiteWidth';

import './index.scss';

class QuoteSlider extends Component {
  constructor(props) {
    super(props);

    this.sliderRef = React.createRef();

    this.sliderSettings = {
      autoplay: true,
      autoplaySpeed: 7000,
      arrows: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }

  render() {

    const { slides, id } = this.props;

    const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';

    if (slides && slides.length > 0) {
      return (
        <div id={id} className={`quote-slider${margin}`}>
          <SiteWidth>
            <Slider {...this.sliderSettings} ref={this.sliderRef}>
              {slides.map((slide, index) => {
                return (
                  <Fragment key={index}>
                    {slide.quote &&
                      <div className="quote-wrap row">
                        <div className="quote-icon">
                          <Quote />
                        </div>
                        <div className="content-wrap">
                          <HtmlBlock content={slide.quote} />
                        </div>
                      </div>
                    }
                  </Fragment>
                )
              })}
            </Slider>
          </SiteWidth>
        </div>
      )
    }

    return null;
  }
}

export default QuoteSlider;
